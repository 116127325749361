<template>
    <b-nav-item-dropdown
        class="languages"
        id="languages-nav-dropdown"
        v-show="accepted_locale.length > 1"
        right
    >
        <template #button-content>
            <b-icon-arrow-clockwise font-scale="1"
                                    animation="spin"
                                    v-if="loading"
            ></b-icon-arrow-clockwise>
            <span>{{ current_locale }}</span>
        </template>
        <b-dropdown-item v-for="locale in accepted_locale" v-bind:key="locale.value"
                         :title="$t(locale.text)"
                         @click.prevent="changeLocale(locale.value)"
                         :hreflang="locale.value">
            {{ $t(locale.value) }}
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>
<script>
import BrandConfig from "../BrandConfig";
import i18n from "../../../common/js/i18n";
import Storage from "../store/Storage";
import {BNavItemDropdown, BDropdownItem, BIconArrowClockwise} from "bootstrap-vue";
import LocaleService from "../service/LocaleService";
import Helpers from "../helpers/Helpers";

export default {
    name: 'Locale',
    components: {BNavItemDropdown, BDropdownItem, BIconArrowClockwise},
    directives: {
        'b-nav-item-dropdown': BNavItemDropdown,
        'b-dropdown-item': BDropdownItem,
        'b-icon-arrow-clockwise': BIconArrowClockwise
    },
    data() {
        return {
            loading: false,
            accepted_locale: [],
            current_locale: Storage.getItem('locale')
        }
    },
    methods: {
        getFlag(locale = null) {
            const l = (locale) ? locale : i18n.locale;
            return "flag-icon-" + (l == 'en' ? 'gb' : l);
        },

        changeLocale(toLocale) {

            i18n.locale = toLocale;
            Storage.setItem('locale', toLocale);

            const localeService = new LocaleService(this.$store);
            this.loading = true;
            localeService.changeLocale(toLocale).then(() => {
                if (this.$store.getters["common/isAuthorized"]) {
                    localeService.updateCustomerLocale(toLocale).then(() => {
                        this.$root.$emit('locale-customer-changed', toLocale);
                    });
                }
                this.$emit('locale-changed',toLocale);
                this.loading = false;
            });

            this.$root.$emit("locale-changed",toLocale);
            window.history.pushState('', '',
                Helpers.path(this.$router.resolve({
                    name: this.$route.name
                }).href, toLocale, this.$store.getters["common/brandMetaItem"]('supported_locale'))
            );
        }
    },
    mounted() {
        this.accepted_locale = BrandConfig.accepted_locale[BrandConfig.brandName];
    },
    computed: {
        flag: {
            get() {
                return this.getFlag();
            },
            set(value) {

            }
        }
    },

}
</script>
<style lang="scss">
.languages {
    .dropdown-menu {
        min-width: 5rem;

        .dropdown-item {
            text-align: left !important;

            &:first-child {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &:last-child {
                padding-bottom: 20px;
            }
        }
    }

    .flag-icon {
        vertical-align: middle;
        margin-top: -8px;
        border-radius: 3px;
    }
}


</style>
