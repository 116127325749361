<template>
    <div>
        <footer>
            <div class="container contentbox">
                <router-link :to="{name:'home', params: { locale: locale }}" class="footer-logo" :title="brand_slug">
                    <img :src="require(`../../img/${brand_slug}/logo-white.svg`)" />
                </router-link>
                <div class="items">
                    <ul>
                        <li>
                            <h3>Certyfikaty</h3>
                            <div class="txtbox">
                                <div class="txt">
                                    LAUR MADE IN KOSZALIN 2017 Deloitte Technology Fast 50 Central Europe 2017 Diament Forbesa 2023 Perła Biznesu 2023 Gwarancja jakości
                                </div>
                                <div class="subtitle">Gwarancja jakości</div>
                            </div>
                        </li>
                        <li>
                            <h3>Informacje</h3>
                            <div class="txtbox">
                                <ul>
                                    <li>
                                        <a href="">FAQ</a>
                                    </li>
                                    <li>
                                        <a href="">Reklamacje</a>
                                    </li>
                                    <li>
                                        <a href="">Regulamin</a>
                                    </li>
                                    <li>
                                        <a href="">Polityka prywatności</a>
                                    </li>
                                    <li>
                                        <a href="">BLOG</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3>Firma</h3>
                            <div class="txtbox">
                                <ul>
                                    <li>
                                        <a href="">O nas</a>
                                    </li>
                                    <li>
                                        <a href="">Branże</a>
                                    </li>
                                    <li>
                                        <a href="">Usługi</a>
                                    </li>
                                    <li>
                                        <a href="">Języki</a>
                                    </li>
                                    <li>
                                        <a href="">Case Study</a>
                                    </li>
                                    <li>
                                        <a href="">Praca</a>
                                    </li>
                                    <li>
                                        <a href="" class="login">Zaloguj się</a>
                                    </li>
                                </ul>
                                <div class="smbox">
                                    <a href="#" class="sm-fb"></a>
                                    <a href="#" class="sm-in"></a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3>Dane</h3>
                            <div class="txtbox">
                                <div class="txt">
                                    dogadamycie.pl Sp. z o.o.
                                    <br /><br />
                                    ul. Zwycięstwa 115 75-601 Koszalin, Polska
                                    <br /><br />
                                    NIP PL 6692518347<br />
                                    D-U-N-S Number: 426900047
                                    <br /><br />
                                    <div class="data-contact">
                                        <a href="">+48 22 460 29 90</a>
                                        <a href="mailto:info@dogadamycie.pl">info@dogadamycie.pl</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import Locale from "./Locale";
import {
    BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
} from "bootstrap-vue";
import i18n from "../../../common/js/i18n";
import BrandConfig from "../BrandConfig";
export default {
    name: 'Footer',
    components: {
        Locale, BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
        BNavbar,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
    },
    data() {
        return {
            locale: i18n.locale,
            brand_slug: BrandConfig.brandName
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {

    },
    methods: {

    }
}
</script>

